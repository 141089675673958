import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { dialog } from '../DataDialog/DataDialog';
import AuthDialog from '../Auth/AuthDialog';
import { fontStyle } from '../SnippetEditor/fontConfig';
import { DataBaseIdEmbedRouteWrapper as DatabaseIdEmbed } from '../Database/DatabaseIdEmbed';
import { Helmet } from 'react-helmet-async';
import DatabasePublicPageEmbed from '../Database/DatabasePublicPageEmbed';
import { useIsPublicUrl } from '../../hooks/useIsPublicUrl';
import { spacesTrashDialog } from '../Database/database_utilities';
import ApiDocs from '../Database/ApiDocs';
import DatabaseCopy from '../Database/DatabaseCopy';
import DBImporter from '../DBImporter/DBImporter';
import DataInnerMain from './DataInnerMain';
import { BundleCopy } from '../Database/BundleCopy';
import CommonDialogs from './CommonDialogs';
import useTokenCookie from '../../hooks/useTokenCookie';
const DatabasePublicSpaceEmbed = React.lazy(() => import('../Database/DatabasePublicSpaceEmbed'));


function DataAppInner() {
  const isPublicUrl = useIsPublicUrl();
  useTokenCookie();
  return (
    <>
      <Helmet defer={false}>
        <title>Data Blaze</title>
      </Helmet>
      <style>{fontStyle}</style>
      <Switch>
        <Route
          exact path={[
            '/space/:id',
            '/space/:id/table/:tid',
            '/space/:id/table/:tid/row/:rid',
            '/space/:id/table/:tid/view/:vid',
            '/space/:id/table/:tid/view/:vid/row/:rid',
          ]}
          render={() => <ErrorBoundary>
            <DatabaseIdEmbed />
          </ErrorBoundary>}
        />
        <Route exact path="/spaces/copy" render={() => <ErrorBoundary>
          <DatabaseCopy mode="space" />
        </ErrorBoundary>} />
        <Route exact path="/bundle/copy" render={() => <ErrorBoundary>
          <DatabaseCopy mode="bundle" />
        </ErrorBoundary>} />
        <Route exact path="/bundle/copy/:bundleId" render={() => <ErrorBoundary>
          <BundleCopy />
        </ErrorBoundary>} />
        <Route
          exact path={[
            '/public/grid/:publicViewId',
            '/public/gallery/:publicViewId',
            // TODO BACKWARD COMPATIBILITY remove /:mode part March 2024
            '/public/form/:formId/:mode(preview)?',
          ]}
          render={() => <ErrorBoundary>
            <DatabasePublicPageEmbed />
          </ErrorBoundary>}
        />
        <Route exact path="/public/embed/space/" render={() => <ErrorBoundary>
          <DatabasePublicSpaceEmbed />
        </ErrorBoundary>} />
        <Route exact path="/datablaze/import/" render={() => <ErrorBoundary>
          <DBImporter />
        </ErrorBoundary>} />
        <Route exact path="/api-docs/database/:id" render={() => <ErrorBoundary>
          <ApiDocs />
        </ErrorBoundary>} />
        <Route component={DataInnerMain} />
      </Switch>

      {!isPublicUrl && <AuthDialog />}
      <CommonDialogs />
      {spacesTrashDialog()}
      <ErrorBoundary dialog details="in the data dialog">{dialog}</ErrorBoundary>
    </>
  );
}


export default DataAppInner;
