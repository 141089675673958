import { currentIdToken, store, waitForLogin } from '@store';
import { useEffect, useRef } from 'react';
import { TABLES_BACKEND_ASGI_DOMAIN } from './useTables';
import { getSessionCookie } from '../session_utils';


const MAXIMUM_RECONNECTION_ATTEMPTS = 10;
// the maximum timeout for the time-based rolling map of consumed messages
const MAXIMUM_REPEATED_MESSAGE_TIMEOUT = 5;

/**
 * This hook is used to connect to Baserow with a web socket and listen to live updates
 * @param {{page: string} & Record<string, object>} pageData The Baserow's page and parameters to listen to live updates from
 * @param {(object) => void} messageCallback The callback to be invoked when a new message is received
 */
export const useTablesSocket = (pageData, messageCallback) => {
  const socket = useRef(null);
  const reconnect = useRef(window.navigator.onLine);
  const attempts = useRef(0);
  const delay = useRef(1000);
  const onOnline = useRef(null);
  const url = new URL(TABLES_BACKEND_ASGI_DOMAIN);
  // a rolling map of {UNIX_TIMESTAMP_IN_SECONDS: {<CONSUMED_MESSAGE_ID>: true}}
  const consumedMessages = useRef(/** @type {{[key: string] : { [key:string]: true}}} */{});
  url.protocol = TABLES_BACKEND_ASGI_DOMAIN.startsWith('https:') ? 'wss:' : 'ws:';
  url.pathname = '/ws/core/';

  useEffect(() => {
    (async function () {
      // make sure token will be available
      await waitForLogin();

      function onopen() {
        resetAttempts();
        // subscribe to the spaces channel
        socket.current.send(
          JSON.stringify(pageData)
        );
      }

      function onmessage(message) {
        let data;
        try {
          data = JSON.parse(message.data);
        } catch {
          return;
        }

        if (data) {
          if (data.type === 'authentication') {
            // update the store with the provided web_socket_id
            // this is necessary to be sent in all subsequent call to tables
            // in order to avoid receiving updates about self performed actions
            store.dispatch({
              type: 'SET_WEB_SOCKET_ID',
              webSocketId: data.web_socket_id,
            });
          } else {
            // WORKAROUND same message can be sent multiple times in case the share permissions included the user
            // in different contexts (like team and email)
            if (data._id) {
              // check if the message is already consumed within the last MAXIMUM_REPEATED_MESSAGE_TIMEOUT seconds
              const currentTimeInSeconds = Math.floor(Date.now() / 1000);
              let isConsumed = false;
              for (let i = 0; i < MAXIMUM_REPEATED_MESSAGE_TIMEOUT; i++) {
                const checkingSecond = currentTimeInSeconds - i;
                if (consumedMessages.current[checkingSecond] && consumedMessages.current[checkingSecond][data._id]) {
                  isConsumed = true;
                  break;
                }
              }

              // delete obsolete entries
              const maxTimeInSeconds = currentTimeInSeconds - MAXIMUM_REPEATED_MESSAGE_TIMEOUT;
              for (const second of Object.keys(consumedMessages.current)) {
                if (Number(second) < maxTimeInSeconds) {
                  delete consumedMessages.current[second];
                }
              }

              if (isConsumed) {
                // escape processing the message
                return;
              }

              // mark the message as consumed
              if (!consumedMessages.current[currentTimeInSeconds]) {
                consumedMessages.current[currentTimeInSeconds] = { [data._id]: true };
              } else {
                consumedMessages.current[currentTimeInSeconds][data._id] = true;
              }
            }

            messageCallback(data);
          }
        }
      }

      function onclose() {
        // reconnect on failures
        delayedReconnect();
      }

      async function connect() {
        let tokenQuery;
        const sessionCookie = getSessionCookie();
        if (sessionCookie) {
          tokenQuery = `session_token=${sessionCookie['token']}`;
        } else {
          const token = await currentIdToken();
          tokenQuery = `token=${token}`;
        }
        socket.current = new WebSocket(`${url}?${tokenQuery}`);
        socket.current.onopen = onopen;
        socket.current.onmessage = onmessage;
        socket.current.onclose = onclose;
      }

      function delayedReconnect() {
        if (!reconnect.current) {
          return;
        }

        attempts.current++;
        if (attempts.current > MAXIMUM_RECONNECTION_ATTEMPTS) {
          // exceeded maximum connection attempts, don't try more
          reconnect.current = false;
          socket.current.close();
          socket.current = null;
          return;
        }

        setTimeout(connect, delay.current);
        delay.current *= 2;
      }

      onOnline.current = () => {
        reconnect.current = true;
        resetAttempts();
        delayedReconnect();
      };

      window.addEventListener('online', onOnline.current);
      delayedReconnect();
    })();


    function resetAttempts() {
      attempts.current = 0;
      delay.current = 1000;
    }

    function onOffline() {
      reconnect.current = false;
      socket.current.close();
    }

    window.addEventListener('offline', onOffline);
    return () => {
      reconnect.current = false;
      if (socket.current) {
        socket.current.close();
      }

      window.removeEventListener('offline', onOffline);
      if (onOnline.current) {
        window.removeEventListener('online', onOnline.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

};
