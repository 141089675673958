import React, { useEffect, useState } from 'react';
import { Box, Divider, Tooltip, Typography as T } from '@mui/material';
import PublicIcon from '@mui/icons-material/PublicOutlined';
import { BetaFeature, TextWithCopy } from '../Group/GroupPublicControls';
import AsyncButton from '../AsyncButton/AsyncButton';
import { DATA_APP_DOMAIN, isBlaze } from '../../flags';
import CircularProgress from '@mui/material/CircularProgress';
import { showConfirm, toast } from '../../message';
import { TemplateShare } from '../Templates/TemplatePublicControl';
import { useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import AppLink from '../Utilities/AppLink';
import { doSafeTableRequest, useTables } from '../../hooks/useTables';
import { decompressDelta } from '../../delta_proto/DeltaProto';
import { makeRef } from '../../firebase_utilities.js';
import { storage } from '../../utilities.js';
import { useLinkedGroups } from './database_utilities.jsx';

/**+
 *
 * @param {Object} props
 * @param {Object} props.database
 * @param {Object} props.setIsPublic
 */
export function DatabasePublishedControls(props) {
  const isBlazeUser = useTypedSelector(store => isBlaze(store.userState));

  const { data: initialBundle, loading } = useTables(`database/bundles/${props.database.id}/`);
  const [bundle, setBundle] = useState(/** @type PublishedBundle|false */(null));
  const [selectedGroup, setSelectedGroup] = useState('none');

  const linkedGroups = useLinkedGroups(props.database.id);
  let { templateData, templateDataLoading } = useTypedSelectorShallowEquals(store => {
    const templates = store.dataState && store.dataState.templates;

    return {
      templateDataLoading: templates === null,
      templateData: templates?.find(template => template.id === props.database.id)
    };
  });


  const isPublic = !!bundle;
  const bundleOrSpaceText = bundle && !!bundle.snippets ? 'bundle' : 'space';

  useEffect(() => {
    if (!initialBundle) {
      return;
    }

    setBundle(initialBundle);

    if (initialBundle.group_id) {
      setSelectedGroup(initialBundle.group_id);
    }
  }, [initialBundle]);

  async function handleRepublish(done) {
    const response = await publishDatabase('PATCH', selectedGroup, props.database, linkedGroups);

    done();

    if (response) {
      props.setIsPublic(!!response);
      setBundle(response);
    }
  }

  async function handleUnpublish(done) {
    const response = unPublishDatabase(props.database.id);

    if (templateData) {
      storage.delete(makeRef('templates_gallery_templates', props.database.id));
    }

    done();

    if (response) {
      props.setIsPublic(false);
      setBundle(null);
    }
  }

  if (!isPublic) {
    return null;
  }

  if (loading) {
    return <div style={{ paddingTop: '20px', marginBottom: 30, justifyContent: 'center', display: 'flex' }}>
      <CircularProgress size={150} thickness={1.9}/>
    </div>;
  }

  const groupLabel = (group) => {
    let label = group.name;
    if (group.icon) {
      label = `${group.icon} ${label}`;
    }

    return <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', marginLeft: 6, marginRight: 6, width: '100%', minHeight: 40 }}>
      <div style={{ flexGrow: 1 }}>
        {label}
      </div>
      {group.id !== 'none' && <OpenFolderInNew groupId={group.id}/>}
    </div>;
  };

  return (
    <>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        mb: 2,
        mt: 4
      }}>

        <PublicIcon fontSize="small" sx={{ mr: 1 }}/>
        <T variant="h6">This is a template {bundleOrSpaceText}</T>

        <BetaFeature />
      </Box>

      {templateData && templateData.paid
        ? <T paragraph>This template {bundleOrSpaceText} is listed in the templates library as paid. Users can only copy it via the hidden URL, but can preview it using the URL below.</T>
        : <T paragraph>Anyone who has the template URL can preview it and copy it to their account.</T>
      }

      {bundle && <TextWithCopy url={
        !!bundle.snippets ?
          `${DATA_APP_DOMAIN}/bundle/copy/${props.database.id}` :
          `${DATA_APP_DOMAIN}/spaces/copy?space=${bundle.application_id}`}
      />}
      {isBlazeUser && <TextWithCopy content={`[bundle id="${props.database.id}"]\n[/bundle]`} />}
      {isBlazeUser && <TextWithCopy content={`{{% bundle %}}\n@id=${props.database.id}\n{{% /bundle %}}`} />}

      {!!linkedGroups.length &&
        <Box mt={1} mb={3}>
          <T variant="subtitle1">Select a snippet's folder and update the template to share it alongside the space{selectedGroup !== 'none' ? ', or deselect it to only share the space.' : '.'}</T>
          <Select
            variant="outlined"
            fullWidth
            aria-label="select folder"
            size="small"
            value={selectedGroup}
            onChange={e => setSelectedGroup(e.target.value)}
          >
            <MenuItem value="none">{groupLabel({ id: 'none', name: '<No Folder>' })}</MenuItem>
            {linkedGroups.map(group => <MenuItem key={group.id} value={group.id}>{groupLabel(group)}</MenuItem>)}
          </Select>
        </Box>}

      <Box textAlign="right" mt={2}>
        <AsyncButton
          data-testid="group-publish-button"
          variant="outlined"
          color="error"
          onClick={(done) => {
            showConfirm({
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Delete',
              contents: templateData
                ? <T>
                  This template
                  {templateData.publishingState === 'approved' ? ' is listed in the ' : ' has a submission for the '}
                  templates library. Deleting it it will also remove its template.
                  {templateData.paid &&
                    <b style={{ display: 'block', marginTop: '4px' }}>Any users that paid for this template won't be
                      able to copy it anymore.</b>}
                </T>
                : <T>
                  This will delete the template, but won't affect the original space.
                </T>
              ,
              onConfirm: () => handleUnpublish(done),
              onCancel: () => done()
            });
          }}
          sx={{ mr: 2 }}
        >
            Delete template
        </AsyncButton>

        <AsyncButton
          data-testid="group-publish-button"
          variant="outlined"
          color="primary"
          onClick={(done) => {
            showConfirm({
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Update template',
              contents: <T paragraph>Updating a template will make it have the latest changes, but it invalidates the previous URL.</T>,
              onConfirm: () => handleRepublish(done),
              onCancel: () => done()
            }
            );
          }}
        >
          Update template
        </AsyncButton>
      </Box>

      <TemplateShare isLoading={templateDataLoading} templateData={templateData} collectionId={props.database.id} collectionType="space" />
    </>
  );
}


/**
 *
 * @param {Object} props
 * @param {Object} props.database
 * @param {Function} props.setIsPublic
 */
export function DatabasePublishControl(props) {
  const linkedGroups = useLinkedGroups(props.database.id);

  async function handlePublish(done) {
    const response = await publishDatabase('POST', 'none', props.database, linkedGroups);

    if (response) {
      props.setIsPublic(true);
    }
    done();
  }

  return (
    <>
      <Divider sx={{ my: 3 }}/>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        mb: 2
      }}>

        <PublicIcon fontSize="small" sx={{ mr: 1 }}/>
        <T variant="h6">Share with everyone</T>

        <BetaFeature />
      </Box>

      <T paragraph>Template spaces allow anyone with the URL to view a snapshot of your data and make a copy of it.</T>

      <Box textAlign="right" mt={2}>
        <AsyncButton
          data-testid="group-publish-button"
          variant="outlined"
          color="primary"
          onClick={handlePublish}
        >
          Publish template space
        </AsyncButton>
      </Box>
    </>
  );
}

/**
 * @param {object} props
 * @param {string} props.groupId
 */
function OpenFolderInNew(props) {
  return <IconButton
    sx={{ padding: 0, flexShrink: 0 }}
    component={AppLink}
    to={`/folder/${props.groupId}`}
    appType="TEXT"
    target="_blank"
  >
    <Tooltip title="View folder">
      <OpenInNewIcon />
    </Tooltip>
  </IconButton>;
}

/**
 * @typedef {object} PublishedBundle
 * @property {string} name
 * @property {any[]=} snippets
 * @property {string=} group_id
 * @property {string=} application_id
 */

/**
 * @param {"POST"|"PATCH"} method
 * @param {string} selectedGroup
 * @param {Object} db
 * @param {Object[]} linkedGroups
 * @return {Promise<PublishedBundle|false>}
 */
async function publishDatabase(method, selectedGroup, db, linkedGroups) {
  const bundle = createBundle(selectedGroup,linkedGroups, db.name);
  const response = await doSafeTableRequest(
    `database/bundles/${db.id}/`,
    method,
    bundle,
  );

  if (response?.error) {
    toast(response.detail, {
      duration: 6000,
      intent: 'danger'
    });

    return false;
  }

  return response;
}

/**
 * @param {string} groupId
 * @param {Object[]} linkedGroups
 * @param {string} dbName
 * @returns {PublishedBundle}
 */
function createBundle(groupId, linkedGroups, dbName) {
  const group = groupId === 'none' ? null : linkedGroups.find(group => group.id === groupId);
  if (!group) {
    // the group is either deleted or the user no longer has access to it
    return {
      name: dbName,
    };
  }

  return {
    group_id: groupId,
    name: group.name,
    snippets: group.snippets.map(snippet => ({
      name: snippet.name,
      shortcut: snippet.shortcut,
      content: replaceSnippetSpaceIds(decompressDelta(snippet.content.delta.toUint8Array())),
    }))
  };
}

function replaceSnippetSpaceIds(delta) {
  delta.ops = delta.ops.map(op => replaceSnippetSpaceIdsInOp(op));
  return delta;
}

function replaceSnippetSpaceIdsInOp(op) {
  if (op.insert && typeof op.insert === 'string') {
    return {
      ...op,
      insert: op.insert.replace(/space=\s*[0-9a-zA-Z]{22}\s*([;}])/g, 'space=id$1'),
    };
  }

  return op;
}

/**
 * @param {string} dbId
 */
async function unPublishDatabase(dbId) {
  try {
    const deleteResponse = await doSafeTableRequest(
      `database/bundles/${dbId}/`,
      'DELETE',
      null,
      { toastMessage: 'An error occurred unpublishing the bundle.' }
    );
    if (deleteResponse?.error) {
      toast(`Error unpublishing bundle: ${deleteResponse.detail}`, {
        duration: 6000,
        intent: 'danger'
      });

      return false;
    } else {
      return true;
    }
  } catch {
    // nothing to do, toast message should be displayed
  }

  return false;
}